import React, { FC } from 'react';
import { Link } from 'gatsby';
import { uniqueId } from 'lodash';

import Image from 'common/Image';

import { BrandLogoListProps, LogoProps } from './models';

import './BrandLogoList.scss';

const BrandLogoList: FC<BrandLogoListProps> = ({ list, customClassName }) => {
  const renderBrandList =
    list !== undefined
      ? list.map((Logo: LogoProps) => {
          const { image, title, link } = Logo;
          const { url } = link !== undefined ? link : { url: '#' };
          const altTitle = title !== undefined ? title : '';
          const originalAltText =
            image !== undefined
              ? image.childImageSharp.gatsbyImageData.images.fallback.src
              : altTitle;
          const imageName = originalAltText.split('/').pop();

          return (
            <Link key={uniqueId()} to={url}>
              {image !== null && <Image className="logo-item" imageData={image} alt={imageName} />}
            </Link>
          );
        })
      : [];

  return <div className={`mx-auto col-12 mb-0 ${customClassName}`}>{renderBrandList}</div>;
};

export default BrandLogoList;
