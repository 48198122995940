import React from 'react';
import { uniqueId } from 'lodash';

import { Layout } from 'layout';
import BrandLogoList from 'layout/BrandLogoList';
import ConditionalTextSlider from 'layout/ConditionalTextSlider';
import Links from 'layout/Links';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import './homeComponent.scss';

const HomeComponent = ({ home: { content } }) => {
  const {
    homePageLink,
    conditionalTextSlider,
    brandLogoList,
    title,
    subTitle,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
  } = content;
  const { slider, customClassName } =
    conditionalTextSlider !== undefined
      ? conditionalTextSlider[0]
      : { slider: [], customClassName: '' };
  const { list, className } =
    brandLogoList !== undefined ? brandLogoList[0] : { list: [], className: '' };
  const renderLinks = homePageLink.map((links) => {
    const { name } = links.link !== undefined ? links.link[0] : uniqueId();

    return (
      <div key={name} className="content__column col-6 p-1 p-md-2">
        <Links links={links} />
      </div>
    );
  });

  return (
    <Layout>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />

      <div className="main container-fluid">
        <div className="grid__row row">
          <div className="grid__column--12 col-12 p-0">
            {slider.length > 0 && (
              <ConditionalTextSlider slider={slider} className={customClassName} />
            )}
          </div>
          {list.length > 0 && <BrandLogoList list={list} customClassName={className} />}
          <div className="grid__column--12 col-12">
            <div className="content container">
              <div className="content__title">
                <h1 className="title">{title}</h1>
              </div>
              <div className="content__description">
                <div className="content__subtitle">{subTitle}</div>
                <div className="content__row row justify-content-center text-center">
                  {renderLinks}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomeComponent;
