import React, { FC } from 'react';
import { graphql } from 'gatsby';

import HomeComponent from 'layout/HomeComponent';

import { HomePageProps } from './models';

const Home: FC<HomePageProps> = ({ data: { homePage } }) => <HomeComponent home={homePage} />;

export const query = graphql`
  query {
    homePage {
      compositions {
        header {
          ...FragmentHeader
        }
        footer {
          ...FragmentFooter
        }
        mainslider {
          ...FragmentMainSlider
        }
        floatingbutton {
          ...FragmentFloatingButton
        }
      }
      content {
        title
        subTitle
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoAdditionalMeta {
          key
          value
        }
        homePageLink {
          image {
            childImageSharp {
              gatsbyImageData(width: 96, height: 96)
            }
          }
          link {
            name
            url
          }
        }
        conditionalTextSlider {
          customClassName
          slider {
            hasText
            description
            layout
            buttonText
            customClassName
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            imageMobile {
              childImageSharp {
                gatsbyImageData
              }
            }
            link {
              url
            }
          }
        }
        brandLogoList {
          list {
            title
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            link {
              url
            }
          }
          className
        }
      }
    }
  }
`;
export default Home;
