import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { LinksProps } from './models';

const Links: FC<LinksProps> = ({ links: { link, homePageLinkTitle, image } }) => {
  const { url } = link[0];

  return (
    <>
      <div key={homePageLinkTitle} className="content__item">
        <Link to={url} className="item__link link">
          <Image imageData={image} alt={url} />
        </Link>
      </div>
    </>
  );
};
export default Links;
